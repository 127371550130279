import axios from "axios";
import Axios from "axios";
import { getConfig } from "config";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getTickets = async (pageSize, page, search, orderBy, orderDirection) => {
  try {
    let cancel;
    const response = await api.get(
      "/admin/tickets",
      { params: { pageSize, page, search, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const resendSMS = async (id) => {
  try {
    let cancel;
    const response = await api.post(
      "/admin/tickets/" + id + "/resend",
      {},
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getTicket = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/tickets/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deleteTicket = async (id) => {
  try {
    let cancel;
    const response = await api.delete(`/admin/ticket/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const storeTicket = async (data, id = null) => {
  console.log("!1111", data);
  const { apiOrigin = "http://localhost:3001" } = getConfig();
  let url = `${apiOrigin}/api/admin/tickets`;

  if (id) {
    url = `${apiOrigin}/api/admin/tickets/${id}`;
  }

  const token = localStorage.getItem("apiToken");
  // try {
  //   // eslint-disable-next-line no-undef
  //   const response = await fetch(url, {
  //     method: "post",
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       Authorization: `Bearer ${token}`,
  //     },
  //     body: data,
  //   });
  //   const responseJson = await response.json();
  //   return responseJson;
  // } catch (e) {
  //   console.log("saveTicketToServer", e);
  //   return {
  //     error: true,
  //   };
  // }
  await axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    console.log("response", response);
  });
  // try {
  //   let cancel;
  //   const response = await api.post("/admin/tickets", data, {
  //     cancelToken: new CancelToken((c) => {
  //       cancel = c;
  //       requests.push(cancel);
  //     }),
  //   });
  //   return response.data;
  // } catch (error) {
  //   console.log(error);
  //   throw error;
  // }
};

const updateTicket = async (data) => {
  try {
    let cancel;
    const response = await api.put(`/admin/tickets/${data.id}`, data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const refundTicket = async (data) => {
  try {
    let cancel;
    const response = await api.post(`/admin/stripe-invoice/refund`, data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const sendSMS = async (id, message) => {
  try {
    let cancel;
    const response = await api.post(
      `/admin/tickets/${id}/send`,
      { message: message },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const VehicleService = {
  init,
  getTicket,
  getTickets,
  resendSMS,
  deleteTicket,
  storeTicket,
  updateTicket,
  refundTicket,
  sendSMS,
};

export default VehicleService;
