import React from "react";
import MUIDataTable from "mui-datatables";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";

// material-ui icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { makeStyles } from "@material-ui/core/styles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
// apis
import SubscriptionBookingService from "services/SubscriptionBookingService";
import useAPI from "utils/useAPI";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";

import moment from "moment";

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SubscriptionBooking() {
  const api = useAPI();
  let path = "/user-subscription-booking/";
  const [data, setData] = React.useState(null);
  const [sendData, setSendData] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [amount, setAmount] = React.useState(0.0);
  const [message, setMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const viewInvoice = (stripeSubscriptionId) => {
    SubscriptionBookingService.getInvoiceLink(stripeSubscriptionId).then(
      (result) => {
        openInNewTab(result);
      }
    );
  };

  const fetchSubscriptionBooking = () => {
    SubscriptionBookingService.getSubscriptionBookings().then((res) => {
      const d = transform(res);
      setData(d);
    });
  };
  React.useEffect(() => {
    SubscriptionBookingService.init(api);
    fetchSubscriptionBooking();
  }, [api]);

  const filterString = (value) => {
    return value ? value : "NA";
  };

  const cancelSubscription = async (transaction) => {
    SubscriptionBookingService.updateCurrentSubscriptionBooking(
      transaction
    ).then(() => {
      fetchSubscriptionBooking();
    });
  };

  const formatePhone = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  const handleAmount = (event) => {
    setAmount(parseInt(event.target.value));
  };
  const handleMessage = (event) => {
    setMessage(event.target.value);
  };

  const makeRefund = () => {
    console.log("msg", amount, message);
    SubscriptionBookingService.makeRefund(amount, message).then((res) => {
      console.log("!23", res);
    });
  };

  function transform(session) {
    let transformed_subscriptions = session;
    transformed_subscriptions = transformed_subscriptions.map((ses) => {
      return {
        id: ses.id,
        name: filterString(ses.user.name),
        phone: String(formatePhone(ses.user.phone)).toUpperCase() + " ",
        vehicle_name: ses.vehicle_name,
        plan_name: ses.plan ? ses.plan.plan_name : "NA",
        amount: ses.plan ? ses.plan.amount : "NA",
        start_date:
          ses.plan && ses.start_date
            ? moment(ses.start_date).format("MM/DD/YYYY hh:mm A")
            : "NA",
        end_date:
          ses.plan && ses.end_date
            ? moment(ses.end_date).format("MM/DD/YYYY hh:mm A")
            : "NA",
        next_billing_date:
          ses.plan && ses.next_billing_date
            ? moment(ses.next_billing_date).format("MM/DD/YYYY hh:mm A")
            : "NA",
        // ticketUrl: ses.ticketUrl,
        actions: "NA",
        // <>
        //   <Tooltip title="View Invoice" aria-label="">
        //     <IconButton
        //       // onClick={() => {
        //       //   viewInvoice(ses.stripe_subscription_id);
        //       // }}
        //     >
        //       <ReceiptIcon />
        //     </IconButton>
        //   </Tooltip>
        // </>
      };
    });
    return transformed_subscriptions;
  }
  const options = {
    filter: true, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
  };
  let columns = [
    // { label: "ID", key: "id" },
    {
      label: "Name",
      name: "name",
      options: {
        filter: false,
      },
    },
    {
      label: "Phone",
      name: "phone",
      options: {
        filter: false,
      },
    },
    {
      label: "Vehicle",
      name: "vehicle_name",
      options: {
        filter: false,
      },
    },
    {
      label: "Plan",
      name: "plan_name",
    },
    {
      label: "Status",
      name: "plan_status",
    },
    {
      label: "Amount($)",
      name: "amount",
      options: {
        filter: false,
      },
    },
    {
      label: "Start Date",
      name: "start_date",
      options: {
        filter: false,
      },
    },
    {
      label: "End Date",
      name: "end_date",
      options: {
        filter: false,
      },
    },
    {
      label: "Next Billing Date",
      name: "next_billing_date",
      options: {
        filter: false,
      },
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
      },
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color="rose">
                    <CollectionsBookmarkIcon style={{ color: "white" }} />{" "}
                  </CardIcon>
                  <Typography variant="h6">
                    SUBSCRIPTION TRANSACTIONS
                  </Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setModal(false);
            }}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => {
                  setModal(false);
                }}
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <h4 className={classes.modalTitle}>Enter Refund Amount</h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <TextField
                id="outlined-multiline-static"
                label="Refund Amount"
                defaultValue="0"
                variant="outlined"
                fullWidth
                type="number"
                value={amount}
                onChange={handleAmount}
                // inputProps={{
                //   maxLength: 13,
                //   step: "2",
                // }}
                margin="dense"
              />
              <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={4}
                defaultValue=""
                variant="outlined"
                fullWidth
                onChange={handleMessage}
                margin="dense"
              />
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setModal(false);
                  setAmount(0);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  if (amount != 0) {
                    setModal(false);
                    console.log(amount, message);
                    makeRefund();
                    setAmount(0);
                  }
                }}
                color="primary"
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Cancel Subscription"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to cancel this subscription?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                variant="outlined"
              >
                Disagree
              </Button>
              <Button
                onClick={() => {
                  cancelSubscription(sendData);
                  setOpen(false);
                }}
                variant="outlined"
                color="primary"
                autoFocus
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
