import React from "react";
import MUIDataTable from "mui-datatables";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import { makeStyles } from "@material-ui/core/styles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import TicketService from "services/TicketService";
import useAPI from "utils/useAPI";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import TransactionService from "services/TransactionService";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import ListAltIcon from "@material-ui/icons/ListAlt";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import EvStationIcon from "@material-ui/icons/EvStation";
import BatteryChargingIcon from "@material-ui/icons/BatteryCharging20";
import CraeteIcon from "@material-ui/icons/Create";
import LinkIcon from "@material-ui/icons/Link";
import SmsIcon from "@material-ui/icons/Sms";
import AddIcon from "@material-ui/icons/Add";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ResendIcon from "@material-ui/icons/Cached";

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const vehicleStatusList = [
  { id: 0, name: "unparked" },
  { id: 1, name: "parked" },
  { id: 2, name: "retrieved" },
];

const getMappedStatus = (ticket) => {
  const status = ticket.vehicle_status;
  if (status === "unparked") {
    return "Start Service";
  }
  if (status === "parked") {
    return "Service in Progress";
  }
  if (status === "retrieved") {
    return "Service Complete";
  }
  return String(status).toUpperCase();
};

export default function Tickets() {
  const api = useAPI();
  const history = useHistory();
  let path = "/ticket/edit/";
  const [modal, setModal] = React.useState(false);
  const [vehicleStatus, setVehicleStatus] = React.useState("");
  const [ticketId, setTicketId] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [bookingId, setBookingId] = React.useState(0);
  const [data, setData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [sendData, setSendData] = React.useState(null);
  const classes = useStyles();

  const handleChange = (value) => {
    setVehicleStatus(value);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const viewInvoice = (transaction) => {
    TransactionService.getInvoiceLink(transaction).then((result) => {
      openInNewTab(result);
    });
  };

  const changeStatus = async (data) => {
    await TicketService.updateTicket(data);
  };

  const fetchSubscriptionBooking = () => {
    TicketService.getTickets().then((res) => {
      let d = transform(res.results);
      setData(d);
    });
  };
  React.useEffect(() => {
    TicketService.init(api);
    TransactionService.init(api);
    fetchSubscriptionBooking();
  }, [api]);

  const filterString = (value) => {
    return value ? value : "-";
  };

  const refundTicket = async (transaction) => {
    TicketService.refundTicket(transaction).then(() => {
      fetchSubscriptionBooking();
    });
  };

  const handleMessage = (event) => {
    // console.log(event.target.value);
    setMessage(event.target.value);
  };

  const sendSMS = () => {
    console.log("msg", bookingId, message);
    TicketService.sendSMS(bookingId, message).then((res) => {
      console.log("!23", res);
      setMessage("");
    });
  };

  function transform(session) {
    let transformed_ticket = session;
    transformed_ticket = transformed_ticket.map((ses) => {
      return {
        id: ses.id,
        name: filterString(ses.name),
        phone: ses.phone,
        vehicle_type: ses.vehicle_type,
        plate: filterString(ses.plate),
        color: filterString(ses.color),
        vehicle_exit: filterString(ses.vehicle_exit),
        vehicle_damage: filterString(ses.vehicle_damage),
        payment_status: (
          <>
            {" "}
            <div display="flex">
              <FormControlLabel
                control={
                  <Switch
                    checked={ses.payment_status == "paid" ? true : false}
                    // checked={true}
                    onChange={(e) => {
                      changeStatus({
                        id: ses.id,
                        payment_status: e.target.checked ? "paid" : "unpaid",
                      }).then(() => {
                        fetchSubscriptionBooking();
                      });
                    }}
                    name="payment_status"
                  />
                  // String(filterString(ses.payment_status)).toUpperCase()
                }
                label={
                  ses.payment_status
                    ? String(capitalizeFirstLetter(ses.payment_status))
                    : "NA"
                }
              />
            </div>
          </>
        ),
        payment_type: String(filterString(ses.payment_type)).toUpperCase(),
        ticketUrl: ses.ticketUrl,
        total_amount: ses.total_amount ? ses.total_amount : "NA",
        vehicle_status: (
          <>
            {getMappedStatus(ses) + " "}
            <div display="flex">
              {ses.vehicle_status == "unparked" && (
                <Tooltip title="Service" aria-label="">
                  <IconButton
                    onClick={() => {
                      // setModal(false);
                      setVehicleStatus("parked");
                      setTicketId(ses.id);
                      changeStatus({
                        id: ses.id,
                        vehicle_status: "parked",
                      }).then(() => {
                        fetchSubscriptionBooking();
                      });
                      setVehicleStatus("");
                      setTicketId(0);
                    }}
                  >
                    <ArrowUpwardIcon color="primary" />
                  </IconButton>
                </Tooltip>
              )}
              {ses.vehicle_status == "parked" && (
                <Tooltip title="Retrieve" aria-label="">
                  <IconButton
                    onClick={() => {
                      setVehicleStatus("retrieved");
                      setTicketId(ses.id);
                      changeStatus({
                        id: ses.id,
                        vehicle_status: "retrieved",
                      }).then(() => {
                        fetchSubscriptionBooking();
                      });
                      setVehicleStatus("");
                      setTicketId(0);
                    }}
                  >
                    <ArrowDownwardIcon style={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}
              {ses.vehicle_status == "uncharged" && (
                <Tooltip title="Charge" aria-label="">
                  <IconButton
                    onClick={() => {
                      setVehicleStatus("charging");
                      setTicketId(ses.id);
                      changeStatus({
                        id: ses.id,
                        vehicle_status: "charging",
                      }).then(() => {
                        fetchSubscriptionBooking();
                      });
                      setVehicleStatus("");
                      setTicketId(0);
                    }}
                  >
                    <BatteryChargingIcon color="primary" />
                  </IconButton>
                </Tooltip>
              )}
              {ses.vehicle_status == "charging" && (
                <Tooltip title="Charged" aria-label="">
                  <IconButton
                    onClick={() => {
                      setVehicleStatus("charged");
                      setTicketId(ses.id);
                      changeStatus({
                        id: ses.id,
                        vehicle_status: "charged",
                      }).then(() => {
                        fetchSubscriptionBooking();
                      });
                      setVehicleStatus("");
                      setTicketId(0);
                    }}
                  >
                    <EvStationIcon style={{ color: "green" }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </>
        ),
        actions: (
          <>
            <div display="flex">
              <Tooltip title="Update Ticket" aria-label="">
                <IconButton
                  onClick={() => {
                    history.push(path + ses.id);
                  }}
                >
                  <CraeteIcon style={{ color: "brown" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Open Ticket" aria-label="">
                <IconButton
                  onClick={() => {
                    window.open(ses.ticketUrl);
                  }}
                >
                  <LinkIcon style={{ color: "blue" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Send Message" aria-label="">
                <IconButton
                  onClick={() => {
                    setBookingId(ses.id);
                    setModal(true);
                  }}
                >
                  <SmsIcon style={{ color: "#0C3C78" }} />
                </IconButton>
              </Tooltip>
            </div>
            <div display="flex">
              <Tooltip title="Resend Ticket" aria-label="">
                <IconButton
                  onClick={() => {
                    TicketService.resendSMS(ses.id).catch();
                  }}
                >
                  <ResendIcon style={{ color: "#0C3C78" }} />
                </IconButton>
              </Tooltip>
              {ses.vehicle_status == "retrieved" && (
                <>
                  <Tooltip title="View Invoice" aria-label="">
                    <IconButton
                      onClick={() => {
                        window.open(`${ses.ticketUrl}/receipt`);
                      }}
                    >
                      <ReceiptIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {ses.stripe_payment_intent_id && (
                <>
                  <Tooltip title="View Invoice" aria-label="">
                    <IconButton
                      onClick={() => {
                        viewInvoice(ses.stripe_payment_intent_id);
                      }}
                    >
                      <ReceiptIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={ses.stripe_refund_id ? "Refunded" : "Make Refund"}
                    aria-label=""
                  >
                    <span>
                      <IconButton
                        disabled={ses.stripe_refund_id}
                        onClick={() => {
                          setOpen(true);
                          setSendData({
                            id: ses.id,
                            payment_intent: ses.stripe_payment_intent_id,
                            ticketRefund: true,
                          });
                        }}
                      >
                        <AssignmentReturnIcon
                          color={ses.stripe_refund_id ? "secondary" : "primary"}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              )}
            </div>
          </>
        ),
      };
    });
    return transformed_ticket;
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const columns = [
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
      },
    },
    {
      label: "Name",
      name: "name",
    },
    {
      label: "Phone",
      name: "phone",
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data.replace(/[^\d]/g, "");
            let val2 = obj2.data.replace(/[^\d]/g, "");
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      label: "Vehicle Type",
      name: "vehicle_type",
    },
    {
      label: "Plate",
      name: "plate",
    },
    {
      label: "Color",
      name: "color",
    },
    {
      label: "Vehicle Status",
      name: "vehicle_status",
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data.props.children[0];
            let val2 = obj2.data.props.children[0];
            return (
              (val1.length < val2.length ? -1 : 1) * (order === "asc" ? 1 : -1)
            );
          };
        },
      },
    },
    {
      label: "Total Amount($)",
      name: "total_amount",
    },
    {
      label: "Payment Status",
      name: "payment_status",
    },
    {
      label: "Payment Type",
      name: "payment_type",
    },
  ];
  const options = {
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
    customToolbar: () => {
      return (
        <Tooltip title="Add Ticket" aria-label="">
          <IconButton
            onClick={() => {
              history.push("/ticket/create");
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color="rose">
                    <ListAltIcon style={{ color: "white" }} />{" "}
                  </CardIcon>
                  <Typography variant="h6">Tickets</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}

          {/* <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setModal(false);
              setVehicleStatus("");
              setTicketId(0);
            }}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => {
                  setModal(false);
                  setVehicleStatus("");
                  setTicketId(0);
                }}
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <h4 className={classes.modalTitle}>Change Vehicle Status</h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <h5>Are you sure you want to do this?</h5> 
              <Select
                fullWidth
                id="vehicle_status"
                value={vehicleStatus}
                required
                onChange={(event) => {
                  handleChange(event.target.value);
                }}
                // style={("paddingTop" = "10px")}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Vehicle Status *
                </MenuItem>

                {vehicleStatusList &&
                  vehicleStatusList.map((item) => (
                    <MenuItem
                      value={`${item.name}`}
                      key={`${item.id}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {capitalizeFirstLetter(`${item.name}`)}
                    </MenuItem>
                  ))}
              </Select>
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                onClick={() => {
                  setModal(false);
                  setVehicleStatus("");
                  setTicketId(0);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setModal(false);
                  console.log(vehicleStatus);
                  changeStatus({
                    id: ticketId,
                    vehicle_status: vehicleStatus,
                  }).then(() => {
                    console.log("Completed");
                    fetchSubscriptionBooking();
                  });
                  setVehicleStatus("");
                  setTicketId(0);
                }}
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog> */}

          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setModal(false);
              setBookingId(0);
            }}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => {
                  setModal(false);
                  setBookingId(0);
                }}
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <h4 className={classes.modalTitle}>Send SMS</h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={4}
                defaultValue=""
                value={message}
                variant="outlined"
                fullWidth
                onChange={handleMessage}
              />
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setModal(false);
                  setMessage("");
                  setBookingId(0);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setModal(false);
                  console.log(message, bookingId);
                  sendSMS();
                  setMessage("");
                  setBookingId(0);
                }}
                color="primary"
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Refund Transaction"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to refund this transaction?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                variant="outlined"
              >
                Disagree
              </Button>
              <Button
                onClick={() => {
                  refundTicket(sendData);
                  setOpen(false);
                }}
                variant="outlined"
                color="primary"
                autoFocus
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
