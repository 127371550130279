import React from "react";
import { useParams } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import UserService from "services/UserService";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

import { makeStyles } from "@material-ui/core/styles";
import GroupIcon from "@material-ui/icons/Group";
import useAPI from "utils/useAPI";
import { FormControl, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Datetime from "react-datetime";
import LocationService from "services/LocationService";
import moment from "moment";
import MaterialTable, { MTableToolbar } from "material-table";
import UserFormComponent from "./UserForm";

const useStyles = makeStyles(styles);

export default function Edituser() {
  const history = useHistory();
  const api = useAPI();
  const classes = useStyles();
  const [users, setUsers] = React.useState({
    name: "",
    email: "",
    status: "",
    role: "",
  });
  const [vehicleGrid, setVehcileGrid] = React.useState([]);
  const [subscriptionGrid, setSubscriptionGrid] = React.useState([]);
  const [locationList, setLocationList] = React.useState([]);
  const status = [
    { name: "waiting", label: "Waiting" },
    { name: "active", label: "Active" },
    { name: "suspend", label: "Suspend" },
  ];
  const roles = [
    { name: "customer", label: "Customer" },
    { name: "admin", label: "Admin" },
    { name: "carousel-admin", label: "Parking Administrator" },
  ];
  const [errors, setErrors] = React.useState({
    name: "",
    status: "",
    location_id: "",
  });

  let { id } = useParams();
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const fetchUsers = async () => {
    await UserService.getUser(id).then((res) => {
      console.log("users", res);
      setUsers(res);
    });
    console.log("here", id);
  };

  const fetchPlans = async () => {
    await UserService.getUserSubscribedPlans(id).then((res) => {
      setSubscriptionGrid(
        res.data.map((item) => ({
          ...item,
          status: String(item.status).replace("_", " ").toUpperCase(),
          is_recurring: item.is_recurring ? "Yes" : "No",
        }))
      );
      console.log("plans", res);
    });
  };

  const handleChange = (prop, val) => {
    setUsers({ ...users, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: "success" });
    } else {
      setErrors({ ...errors, [prop]: "error" });
    }
  };

  const getDate = (dateTime) => {
    return moment(dateTime).format("MM/DD/YYYY hh:mm A");
  };

  const handlesave = async () => {
    let error = 0;
    let newErrors = errors;

    ["name", "status", "location_id"].forEach((field) => {
      if (
        field === "location_id" &&
        users.role === "carousel-admin" &&
        users[field] === null
      ) {
        newErrors[field] = "error";
        error = 1;
      } else if (users[field] === "") {
        newErrors[field] = "error";
        error = 1;
      }
    });
    setErrors({ ...newErrors });

    if (error) return;

    const data = {
      name: users.name,
      email: users.email,
      status: users.status,
      role: users.role,
      role_expiry_date:
        users.role === "carousel-admin"
          ? moment(users.role_expiry_date).format("YYYY-MM-DD")
          : null,
      location_id: users.role === "carousel-admin" ? users.location_id : null,
    };
    await UserService.updateUser(data);
    history.push("/admin/users");
  };

  React.useEffect(() => {
    UserService.init(api);
    LocationService.init(api);
    fetchUsers();
    fetchLocationList();
    fetchPlans();
  }, [api]);

  const fetchLocationList = async () => {
    await LocationService.getLocations().then((res) => {
      setLocationList(
        res.map((item) => {
          return {
            id: item.id,
            name: item.name + " - " + item.zipcode,
            value: item.id,
          };
        })
      );
    });
  };

  React.useEffect(() => {
    if (users.vehicles) {
      setVehcileGrid(
        users.vehicles.map((vehicle) => {
          return {
            id: vehicle.id,
            user_id: vehicle.user_id,
            make: vehicle.make,
            model: vehicle.model,
            plate: vehicle.plate,
            color: vehicle.color,
            zip: vehicle.zip,
          };
        })
      );
    }
    // if (users.subscription) {
    //   setSubscriptionGrid();
    // }
  }, [users]);
  return (
    <GridContainer>
      <UserFormComponent id={id} />
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title="User vehicle list"
            columns={[
              { title: "Make", field: "make" },
              { title: "Model", field: "model" },
              { title: "Plate", field: "plate" },
              { title: "Color", field: "color" },
              { title: "Zip", field: "zip" },
            ]}
            data={vehicleGrid}
            actions={[]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <LocalShippingIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>

      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title="User Subscription"
            columns={[
              { title: "Plan", field: "name" },
              {
                title: "Subscribe Date",
                field: "start_date",
                type: "datetime",
              },
              { title: "Duration", field: "duration" },
              // { title: "Location", field: "plate" },
              { title: "Status", field: "status" },
              { title: "Is Recurring", field: "is_recurring" },
            ]}
            data={subscriptionGrid}
            actions={[]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <LocalShippingIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
