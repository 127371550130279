import React from "react";
import { useParams } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import UserService from "services/UserService";
import VehicleService from "services/VehicleService";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import GroupIcon from "@material-ui/icons/Group";
import useAPI from "utils/useAPI";
import { FormControl, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import { DateTimePicker } from "@material-ui/pickers";
import Loading from "components/Loading";

const useStyles = makeStyles(styles);

function UserForm({ id = null }) {
  const history = useHistory();
  const api = useAPI();
  const classes = useStyles();
  const [users, setUsers] = React.useState({
    name: "",
    email: "",
    phone: "",
    model: "",
    make: "",
    status: "",
    role: "",
    start_time: new Date(),
    end_time: new Date(),
  });

  const [locationList, setLocationList] = React.useState([]);
  const status = [
    { name: "waiting", label: "Waiting" },
    { name: "active", label: "Active" },
    { name: "suspend", label: "Suspend" },
  ];
  const roles = [
    { name: "customer", label: "Customer" },
    { name: "admin", label: "Admin" },
    { name: "carousel-admin", label: "Parking Administrator" },
  ];
  const [errors, setErrors] = React.useState({
    name: "",
    email: "",
    plate: "",
    phone: "",
    model: "",
    make: "",
    status: "",
    role: "",
    start_time: "",
    end_time: "",
  });
  const [label] = React.useState(id ? "Update User" : "Add User");
  const [timerRef, settimerRef] = React.useState("");
  const [vehicleMakes, setVehicleMakes] = React.useState([""]);
  const [vehicleMake, setVehicleMake] = React.useState("");
  const [vehicleModels, setVehicleModels] = React.useState([""]);
  const [vehicleModel, setVehicleModel] = React.useState("");
  const [vehiclePlate, setVehiclePlate] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const fetchUser = async (id) => {
    await UserService.getUser(id).then((res) => {
      console.log("users", res);
      // setUsers(res.vehicles[0]);
      // setUsers({ ...users, ["plate"]: res.vehicles[0].plate });
      fetchVehicleMakes(res.vehicles[0].make);
      setVehicleMake(res.vehicles[0].make);
      setVehicleModel(res.vehicles[0].model);
      setVehiclePlate(res.vehicles[0].plate);
      const formatedStart = moment(res.start_time).format("YYYY-MM-DD HH:mm");
      const formatedEnd = moment(res.end_time).format("YYYY-MM-DD HH:mm");

      const startUTC = moment.utc(formatedStart).toDate();
      const endUTC = moment.utc(formatedEnd).toDate();

      res.start_time = moment(startUTC).local().toDate();
      res.end_time = moment(endUTC).local().toDate();
      setUsers(res);
      setLoading(false);
    });
  };

  const fetchVehicleMakes = async (search) => {
    console.log("search", search);
    await VehicleService.getVehicleMakes(search).then((res) => {
      if (res) {
        setVehicleMakes(res);
      }
    });
  };

  const fetchVehicleModels = async (newValue) => {
    await VehicleService.getVehicleModel(newValue.id).then((res) => {
      if (res) {
        setVehicleModels(res);
      }
    });
  };

  const handleChange = (prop, val) => {
    setUsers({ ...users, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: "success" });
    } else {
      setErrors({ ...errors, [prop]: "error" });
    }
  };

  const getDate = (dateTime) => {
    return moment(dateTime).toDate();
  };

  const handleSave = async () => {
    let error = 0;
    let newErrors = errors;

    ["name", "phone", "make", "model"].forEach((field) => {
      if (
        field === "location_id" &&
        users.role === "carousel-admin" &&
        users[field] === null
      ) {
        newErrors[field] = "error";
        error = 1;
      } else if (users[field] === "") {
        newErrors[field] = "error";
        error = 1;
      }
    });
    setErrors({ ...newErrors });

    if (error) return;

    const data = {
      user_id: users.id,
      name: users.name,
      phone: users.phone,
      email: users.email,
      model: users.model,
      make: users.make,
      plate: vehiclePlate,
      status: users.status,
      role: users.role,
      start_time: moment(users.start_time).utc().format("YYYY-MM-DD HH:mm"),
      end_time: moment(users.end_time).utc().format("YYYY-MM-DD HH:mm"),
      role_expiry_date:
        users.role === "carousel-admin"
          ? moment(users.role_expiry_date).format("YYYY-MM-DD")
          : null,
      location_id: users.role === "carousel-admin" ? users.location_id : null,
    };
    console.log("1123", data, vehicleMake, vehicleModel);
    if (id) {
      await UserService.updateUser(data);
    } else {
      await UserService.storeUser(data);
    }
    history.push("/admin/users");
  };

  React.useEffect(() => {
    UserService.init(api);
    VehicleService.init(api);
    if (id) {
      fetchUser(id);
    } else {
      setLoading(false);
    }
  }, [api]);

  if (loading) {
    return <Loading />;
  }

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <GroupIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{label}</h4>
        </CardHeader>
        <CardBody>
          <form>
            <TextField
              error={errors.name === "error"}
              margin="dense"
              id="name"
              label="Name *"
              variant="outlined"
              type="text"
              fullWidth
              value={users.name}
              onChange={(event) => {
                handleChange("name", event.target.value);
              }}
            />
            <InputMask
              mask="+1 (999) 999 9999"
              value={users.phone}
              disabled={false}
              // onChange={handleChange("phone")}
              onChange={(event) => {
                handleChange("phone", event.target.value);
              }}
              maskChar=" "
            >
              {() => (
                <TextField
                  error={errors.phone === "error"}
                  margin="dense"
                  id="phone"
                  label="Phone *"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              )}
            </InputMask>
            <TextField
              margin="dense"
              id="email"
              label="Email "
              variant="outlined"
              type="text"
              fullWidth
              value={users.email}
              onChange={(event) => {
                handleChange("email", event.target.value);
              }}
            />
            {!id && (
              <Autocomplete
                id="combo-box-demo"
                fullWidth
                margin="dense"
                options={vehicleMakes}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                onInputChange={(event, args) => {
                  try {
                    if (timerRef) {
                      clearTimeout(timerRef);
                    }
                    if (args.length > 2) {
                      settimerRef(
                        setTimeout(() => {
                          fetchVehicleMakes(args);
                        }, 500)
                      );
                    } else {
                      setVehicleMakes([""]);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setUsers({ ...users, make: newValue.name });
                    setErrors({ ...errors, make: "success" });
                    fetchVehicleModels(newValue);
                    // setinputValue(false);
                  } else {
                    setUsers({ ...users, make: "", model: "" });
                    setVehicleModels([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Vehicle Manufacturer (ex. Honda, BMW)"
                    variant="outlined"
                    margin="dense"
                    error={errors.make === "error"}
                    label="Make *"
                  />
                )}
              />
            )}
            {id && (
              <Autocomplete
                id="combo-box-demo"
                fullWidth
                margin="dense"
                options={vehicleMakes}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                value={vehicleMake}
                inputValue={vehicleMake}
                onInputChange={(event, args, reason) => {
                  try {
                    if (reason === "input") {
                      setVehicleMake(args);
                      fetchVehicleMakes(args);
                    }
                    if (timerRef) {
                      clearTimeout(timerRef);
                    }
                    if (args.length > 2) {
                      settimerRef(
                        setTimeout(() => {
                          fetchVehicleMakes(args);
                        }, 500)
                      );
                    } else {
                      setVehicleMakes([]);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setVehicleMake(newValue.name);
                    setVehicleModel("");
                    setUsers({ ...users, make: newValue.name });
                    setErrors({ ...errors, make: "success" });
                    fetchVehicleModels(newValue);
                  } else {
                    setUsers({ ...users, make: "", model: "" });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="dense"
                    error={errors.make === "error"}
                    label="Make *"
                  />
                )}
              />
            )}
            <Autocomplete
              id="combo-box-demo"
              fullWidth
              options={vehicleModels}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              // value={vehicleModels.find(v => v.name === users.model) || {}}
              value={vehicleModel}
              inputValue={vehicleModel}
              onChange={(event, newValue) => {
                setErrors({ ...errors, model: "success" });
                console.log("123!", event, newValue);
                if (newValue) {
                  setVehicleModel(newValue.name);
                  setUsers({ ...users, model: newValue.name });
                } else {
                  setUsers({ ...users, model: "" });
                }
              }}
              onInputChange={(event, args, reason) => {
                if (reason === "input") setVehicleModel(args);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Vehicle Model Name (ex. Accord, 335i)"
                  variant="outlined"
                  margin="dense"
                  error={errors.model === "error"}
                  label="Model *"
                />
              )}
            />
            <TextField
              error={errors.plate === "error"}
              label="Plate"
              margin="dense"
              id="plate"
              value={vehiclePlate}
              variant="outlined"
              type="text"
              fullWidth
              onChange={(event) => {
                setVehiclePlate(event.target.value);
                // handleChange("plate", event.target.value);
              }}
            />
            {users.status && (
              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel id="user_status">User Status</InputLabel>
                <Select
                  labelId="user_status"
                  id="user_status"
                  fullWidth
                  value={users.status}
                  onChange={(event) => {
                    handleChange("status", event.target.value);
                  }}
                  label="User Status"
                >
                  <MenuItem
                    disabled
                    value=""
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Select status
                  </MenuItem>

                  {status.map((status) => (
                    <MenuItem
                      value={`${status.name}`}
                      key={`${status.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${status.label}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {users.role && (
              <FormControl
                fullWidth
                variant="outlined"
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel id="user_role">User Role</InputLabel>
                <Select
                  labelId="user_role"
                  id="user_role"
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  label="User Role"
                  value={users.role}
                  onChange={(e) => {
                    handleChange("role", e.target.value);
                  }}
                  error={errors.color === "error"}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose User Role*
                  </MenuItem>

                  {roles.map((role) => (
                    <MenuItem
                      value={`${role.name}`}
                      key={`${role.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${role.label}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {users.role === "carousel-admin" && (
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Role Expiration Date
                </InputLabel>
                <br />
                <br />
                <Datetime
                  timeFormat={false}
                  utc={true}
                  onChange={(e) => {
                    setUsers({
                      ...users,
                      role_expiry_date: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                  inputProps={{
                    value: users.role_expiry_date
                      ? getDate(`${users.role_expiry_date}`)
                      : "",
                    error: true,
                  }}
                />
              </FormControl>
            )}
            {users.role === "carousel-admin" && (
              <FormControl fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Location
                </InputLabel>
                <Select
                  fullWidth
                  error={errors.location_id === "error"}
                  label="Location"
                  value={users.location_id}
                  required
                  onChange={(e) => {
                    setErrors({ ...errors, location_id: "success" });
                    setUsers({
                      ...users,
                      location_id: e.target.value,
                    });
                  }}
                  // error={errors.color === "error"}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Location *
                  </MenuItem>

                  {locationList &&
                    locationList.map((location) => (
                      <MenuItem
                        value={`${location.id}`}
                        key={`${location.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${location.name}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

            <DateTimePicker
              label="Start Time"
              value={users.start_time}
              inputVariant="outlined"
              autoOk={false}
              margin="dense"
              fullWidth
              format="MM/DD/YYYY hh:mm A"
              onChange={(e) => {
                console.log(e);
                // handleDateChange(e);
                setUsers({
                  ...users,
                  start_time: e, //moment(e).format("YYYY-MM-DD HH:mm:ss"),
                });
              }}
            />

            <DateTimePicker
              label="End Time"
              value={users.end_time}
              inputVariant="outlined"
              margin="dense"
              fullWidth
              format="MM/DD/YYYY hh:mm A"
              onChange={(e) => {
                console.log(e);
                // handleDateChange(e);
                setUsers({
                  ...users,
                  end_time: e, // moment(e).format("YYYY-MM-DD HH:mm:ss"),
                });
              }}
            />

            <GridContainer>
              {/* <GridItem xs={12} sm={12} md={6}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Start Time{" "}
                  </InputLabel>
                  <Datetime
                    utc={true}
                    onChange={(e) => {
                      setUsers({
                        ...users,
                        start_time: moment(e).format("YYYY-MM-DD HH:mm:ss"),
                      });
                    }}
                    inputProps={{
                      value: users.start_time
                        ? getDate(`${users.start_time}`)
                        : "",
                      error: true,
                    }}
                  />
                </GridItem> */}
              {/* <GridItem xs={12} sm={12} md={6}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    End Time{" "}
                  </InputLabel>
                  <Datetime
                    utc={true}
                    onChange={(e) => {
                      setUsers({
                        ...users,
                        end_time: moment(e).format("YYYY-MM-DD HH:mm:ss"),
                      });
                    }}
                    inputProps={{
                      value: users.end_time ? getDate(`${users.end_time}`) : "",
                      error: true,
                    }}
                  />
                </GridItem> */}
            </GridContainer>
            <div className={classes.formCategory}>
              <small>*</small> Required fields
            </div>
            <Button
              color="rose"
              onClick={handleSave}
              className={classes.registerButton}
            >
              {label}
            </Button>
          </form>
        </CardBody>
      </Card>
    </GridItem>
  );
}
UserForm.propTypes = {
  id: PropTypes.string,
};

UserForm.defaultProps = {
  id: null,
};

export default UserForm;
